import { WithAxios } from '@sicarx/sxwa-api';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { CustomSnackProvider } from '@sicarx/sxwa-core';

import './index.css'
import App from './App';

createRoot(document.getElementById('root')!).render(
  <CustomSnackProvider>
    <BrowserRouter>
      <WithAxios>
        <App />
      </WithAxios>
    </BrowserRouter>
  </CustomSnackProvider>
)

// createRoot(document.getElementById('root')!).render(
//   <StrictMode>
//     <CustomSnackProvider>
//       <BrowserRouter>
//         <WithAxios>
//           <App />
//         </WithAxios>
//       </BrowserRouter>
//     </CustomSnackProvider>
//   </StrictMode>
// )
